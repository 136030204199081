.flashcard-container {
    padding: 15px;
    border-radius: 3px;
    background-color: #0e0c0c;
  }
  
  .flashcard-button {
    background: transparent;
    padding: 8px;
    border-radius: 3px;
    border-color: blue;
    margin: 5px;
    width: 150px;
  }
  