.options-container {
  display: flex;
  flex-wrap: wrap;
}

.option-button {
  margin:5px;
  border-radius: 100px;
  padding: 8px;
  border-color: rgb(1, 1, 19);
  border: cyan;
  /* border-width: 100px; */
  background: transparent;
  text-align: center;
}
